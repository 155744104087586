export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    adminAuth: true,
    analystAuth: true,
  },
  {
    title: 'Profile',
    route: 'profile',
    icon: 'UserIcon',
    adminAuth: true,
    analystAuth: true,
    agentAuth: true,
  },
  {
    title: 'TimeSheets',
    icon: 'ClockIcon',
    adminAuth: true,
    children: [
      {
        title: 'Individual',
        route: 'individual-timesheet',
        icon: 'CircleIcon',
      },
      {
        title: 'BreakDown',
        route: 'breakdown-timesheet',
        icon: 'CircleIcon',
      },
    ],
  },
  {
    title: 'Candidate Tracking',
    route: 'candidate-tracking',
    icon: 'TrelloIcon',
    adminAuth: true,
  },
  {
    title: 'Logs',
    route: 'logs',
    icon: 'AirplayIcon',
    adminAuth: true,
  },
  {
    title: 'Settings',
    route: 'settings',
    icon: 'SettingsIcon',
    adminAuth: true,
    children: [
      {
        title: 'Catalogs',
        route: 'catalogs',
        icon: 'BoxIcon',
      },
    ],
  },
]
