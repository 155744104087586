<template>
  <b-nav-item v-if="InOut !== 'Completed Day'">
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      size="sm"
      @click="CheckDay()"
    >
      <feather-icon
        icon="ClockIcon"
        class="mr-50"
      />
      <span class="align-middle">Check {{ InOut }} {{ time }}</span>
    </b-button>
  </b-nav-item>
</template>

<script>
import { BNavItem, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment-timezone'
import timeSheetService from '@core/services/timeSheets/timeSheets'
import Swal from 'sweetalert2'

export default {
  components: {
    BNavItem,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      time: null,
      date: null,
      PayrollDay: null,
      InOut: '',
      IdPayroll: 0,
      variable: '',
    }
  },
  created() {
    this.date = moment().tz('America/Los_Angeles').format('YYYY-MM-DD')
    this.time = moment().tz('America/Los_Angeles').format('h:mm:ss A')
    this.getCheckInOutDay()
  },
  mounted() {
    setInterval(() => {
      this.time = moment().tz('America/Los_Angeles').format('h:mm:ss A')
    }, 1000)
  },
  methods: {
    /* eslint-disable */
    getCheckInOutDay() {
      const User = this.$store.getters['user-auth/getUser']
      timeSheetService.getCheckInOutDay({
        idUser: User.Id,
        currentDate: this.date,
      }).then(response => {
        const { payrollDay } = response.data
        this.InOut = payrollDay.Check_in_out.split('-')[0]
        this.variable = payrollDay.Check_in_out.split('-')[1]
        this.IdPayroll = payrollDay.id_payroll
      })
    },

    CheckDay(){
      Swal.fire({
        title: 'Attention',
        text: `¿Do you want Check ${this.InOut}?`,
        icon: 'warning',
        confirmButtonText: 'Yes',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
      }).then(result => {
        if (result.isConfirmed) {
          const User = this.$store.getters['user-auth/getUser']
          timeSheetService.createCheckInOut({
            idUser: User.Id,
            variable: this.variable,
            id_payroll: this.IdPayroll,
          }).then(response => {
            this.getCheckInOutDay()
          })
        }
      })
    }
    /* eslint-enable */
  },
}
</script>
